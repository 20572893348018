<template>
    <v-container fluid>
        <v-card :loading="loading">
            <Page-Header
                :title="$t('school.studentUpgrading')"
                icon="mdi-chart-box-outline"
            >
                <template v-slot:buttons>
                    <v-btn
                        color="primary darken-2"
                        @click="upgradeDialog = true"
                        dark
                        class="mb-2"
                    >
                        <v-icon class="mx-2">
                            mdi-account-arrow-up-outline
                        </v-icon>
                        {{ $t("school.upgrade") }}
                    </v-btn>
                </template>
            </Page-Header>
            <v-expansion-panels class="pa-5" v-model="panel" multiple>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-icon color="primary darken-1">
                            mdi-clipboard-text-search-outline
                        </v-icon>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-select
                                            outlined
                                            hide-details
                                            dense
                                            :rules="rules"
                                            persistent-hint
                                            v-model="filterSelectedStage"
                                            :items="schoolStages"
                                            item-text="stageName"
                                            item-value="schoolStageGuid"
                                            :label="$t('school.schoolStage')"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-select
                                            outlined
                                            hide-details
                                            dense
                                            :rules="rules"
                                            persistent-hint
                                            v-model="filterSelectedClass"
                                            :items="classes"
                                            item-text="className"
                                            item-value="classGuid"
                                            :label="$t('school.class')"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-btn
                                color="primary darken-1 white--text"
                                @click="refreshTable"
                                v-if="isInRole(91)"
                                :disabled="!valid"
                                :min-width="100"
                            >
                                <v-icon
                                    >mdi-clipboard-text-search-outline</v-icon
                                >
                                {{ $t("preview") }}
                            </v-btn>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-data-table
                class="pb-5 px-5"
                :headers="headers"
                :items="filteredStudents"
                v-model="selectedStudents"
                item-key="studentGuid"
                :loading="loading"
                :loading-text="$t('loading')"
                show-select
            >
                <template v-slot:top>
                    <v-dialog
                        persistent
                        v-model="upgradeDialog"
                        max-width="500px"
                    >
                        <v-form
                            ref="upgradeForm"
                            v-model="valid2"
                            lazy-validation
                        >
                            <v-card>
                                <v-toolbar color="primary darken-1" dark>
                                    <v-card-title>
                                        <span>{{ $t("school.upgrade") }}</span>
                                    </v-card-title>
                                </v-toolbar>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" md="12">
                                                <v-select
                                                    outlined
                                                    hide-details
                                                    dense
                                                    :rules="rules"
                                                    persistent-hint
                                                    v-model="selectedStage"
                                                    :items="schoolStages"
                                                    item-text="stageName"
                                                    item-value="schoolStageGuid"
                                                    :label="
                                                        $t('school.schoolStage')
                                                    "
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="12" md="12">
                                                <v-select
                                                    outlined
                                                    hide-details
                                                    dense
                                                    :rules="rules"
                                                    persistent-hint
                                                    v-model="selectedClass"
                                                    :items="classes"
                                                    item-text="className"
                                                    item-value="classGuid"
                                                    :label="$t('school.class')"
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="12" md="12">
                                                <v-select
                                                    outlined
                                                    hide-details
                                                    dense
                                                    :rules="rules"
                                                    persistent-hint
                                                    v-model="selectedSemester"
                                                    :items="semesters"
                                                    item-text="semesterName"
                                                    item-value="semesterGuid"
                                                    :label="
                                                        $t('school.semester')
                                                    "
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="12" md="12">
                                                <v-select
                                                    outlined
                                                    hide-details
                                                    dense
                                                    :rules="rules"
                                                    persistent-hint
                                                    v-model="selectedPlan"
                                                    :items="plans"
                                                    item-text="title"
                                                    item-value="planGuid"
                                                    :label="
                                                        $t(
                                                            'itemTemplate.templateName'
                                                        )
                                                    "
                                                ></v-select>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        color="primary darken-1 white--text"
                                        @click="upgrade"
                                        :disabled="!valid2"
                                        :min-width="100"
                                    >
                                        {{ $t("school.upgrade") }}
                                        <v-icon class="mx-2"
                                            >mdi-account-arrow-up-outline</v-icon
                                        >
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="red"
                                        @click="closeUpgrade"
                                    >
                                        {{ $t("cancel") }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import PageHeader from "../../components/PageHeader.vue";

export default {
    components: {
        PageHeader
    },
    data() {
        return {
            panel: [0, 1],
            selectedPlan: null,
            selectedSemester: null,
            selectedClass: null,
            selectedStage: null,
            filterSelectedClass: null,
            filterSelectedStage: null,
            selectedStudents: [],
            filteredStudents: [],
            title: null,
            date: null,
            date2: null,
            activePicker: null,
            menu: false,
            activePicker2: null,
            menu2: false,
            valid: true,
            valid2: true,
            loading: true,
            dialog: true,
            upgradeDialog: false,
            currencyGuid: null,
            customerGuid: null,
            headers: [
                {
                    text: this.$t("customer.fullName"),
                    value: "fullName"
                },
                {
                    text: this.$t("school.schoolStage"),
                    value: "stageName"
                },
                {
                    text: this.$t("school.class"),
                    value: "className"
                },
                {
                    text: this.$t("school.section"),
                    value: "customerGroup"
                },
                {
                    text: this.$t("school.semester"),
                    value: "semesterName"
                }
            ],
            students: [],
            schoolStages: [],
            semesters: [],
            classes: [],
            plans: [],
            rules: [value => !!value || this.$t("required")]
        };
    },
    created() {
        if (this.isInRole(91)) {
            this.loading = true;
            this.getMainData();
        } else this.redirectUnauthorizedUsers();
    },
    watch: {},
    methods: {
        closeUpgrade() {
            this.upgradeDialog = false;
            this.$refs.upgradeForm.reset();
            this.$refs.upgradeForm.resetValidation();
        },
        refreshTable() {
            if (this.$refs.form.validate()) {
                var classGuid = this.filterSelectedClass;
                var schoolStageGuid = this.filterSelectedStage;
                this.filteredStudents = this.students.filter(function(obj) {
                    return (
                        obj.classGuid == classGuid &&
                        obj.schoolStageGuid == schoolStageGuid
                    );
                });
            }
        },
        getMainData() {
            axios
                .get("Students/GetStudents")
                .then(response => {
                    this.students = response.data.data;
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });

            axios
                .get("Plans/Get")
                .then(response => {
                    this.plans = response.data.data;
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });

            axios
                .get("Students/GetClasses")
                .then(response => {
                    this.classes = response.data.data;
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });

            axios
                .get("Students/GetSchoolStages")
                .then(response => {
                    this.schoolStages = response.data.data;
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });

            axios
                .get("Students/GetSemesters")
                .then(response => {
                    this.semesters = response.data.data;
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        saveDate(date) {
            this.$refs.menu.save(date);
        },
        saveDate2(date) {
            this.$refs.menu2.save(date);
        },
        print() {
            var defaultCurrency =
                this.currencyGuid == null
                    ? this.currencies.filter(function(obj) {
                          return obj.isMain == true;
                      })[0].currencyGuid
                    : this.currencyGuid;
            axios({
                url: "Customers/PrintCustomerAccountStatementReport",
                method: "GET",
                responseType: "blob",
                params: {
                    currencyGuid: defaultCurrency,
                    customerGuid: this.customerGuid,
                    fromDate: this.fromDate,
                    toDate: this.toDate
                }
            }).then(response => {
                var fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                var fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.setAttribute("download", "تقرير كشف حساب عميل.pdf");
                document.body.appendChild(fileLink);
                fileLink.click();
            });
        },
        upgrade() {
            if (this.$refs.upgradeForm.validate()) {
                if (this.selectedStudents.length == 0) {
                    this.$toast.warning(this.$t("error.PleaseSelectStudents"));
                    return;
                }

                axios
                    .put("Students/UpgradeStudents", {
                        schoolStageGuid: this.selectedStage,
                        classGuid: this.selectedClass,
                        semesterGuid: this.selectedSemester,
                        planGuid: this.selectedPlan,
                        students: this.selectedStudents.map(a => a.studentGuid)
                    })
                    .then(response => {
                        if (response.data.status == "Successful") {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                        this.closeUpgrade();
                        this.refreshTable();
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        this.refreshData();
                        console.log(e);
                    });
            }
        },
        filter() {}
    }
};
</script>
